:root {
  --golden: rgb(255, 187, 59);
  --navy:#000080;
  --white:#fff;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.Divcontat {
  background-color: #0FF;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  font: Verdana;
  font-style: normal;
  color: #033;
  font-size: 15px;
  font-weight: 300;
  height: 450px;
}
.mail-style-details {
  background-color: #F8F8F8;
  float: right;
  padding: 10px;
  margin-top: 10px;
  height: 320px;
}
.spanOffer {
  color: brown;
  width: 280px;
}
.offerhome {
  background-color: #feff8d;
  /* background: #FFF; */
  float: left;
  padding: 10px;
  margin-top: 10px;
}
.dropdown:hover .dropdown-menu {
  display: block;
}


.ftitle{
  color: var(--golden);

}

.topheading{
background-color: var(--navy);
color: var(--white);
}